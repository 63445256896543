import React from "react";

const Simba = () => {
  return (
    <div className="md:w-[350px] lg:w-[325px] xl:w-[350px] w-[300px] mx-auto main_price_pop_card mt-5">
      <div className="flex flex-col justify-start bg-black text-[#F4F4F5] p-6  rounded-[40px] shadow-md">
        <div className="flex flex-col gap-1 justify-center items-center text-center mb-8">
          <h3 className="text-[26px] font-semibold text-[#FF7E1D]">Simba</h3>
          <span className="text-[10px] font-normal leading-[10px] font-[Outfit] tracking-[2px]">
            INSTANT FUNDING
          </span>
        </div>
        <div className="space-y-4">
          {[
            { label: "Min Gain", value: "1%" },
            { label: "Max Gain / Single Payout", value: "5%" },
            { label: "Max Loss", value: "8%" },
            { label: "Daily loss", value: "4%" },
            { label: "Profit Split", value: "50% + 10% Scale Up" },
            { label: "News Trading", value: "All Allowed" },
            { label: "Weekend Holding", value: "None" },
            { label: "Withdrawal", value: "Weekly" },
            { label: "Profit Scale Up", value: "10%", valueTwo: "Each Payout" },
            { label: "Consistency Rule", value: "Applied" },
          ].map((item, index, arr) => (
            <div
              key={index}
              className={`pb-5 ${
                index !== arr.length - 1
                  ? "border-b border-dashed border-[#F4F4F5B2]"
                  : ""
              }`}
            >
              <div className="flex items-center justify-between">
                <span className="font-normal text-[18px] leading-[18px] font-[Outfit]">
                  {item.label}
                </span>

                <div className="flex flex-col items-end gap-1">
                  <span className="text-[16px] leading-4 font-semibold font-[Outfit]">
                    {item.value}
                  </span>
                  {item.valueTwo && (
                    <span className="text-[12px] leading-3 font-semibold font-[Outfit]">
                      {item.valueTwo}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Simba;
