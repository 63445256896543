import React from "react";

const Modal = ({
  isOpen,
  onClose,
  title,
  content,
  contentTwo,
  contentThree,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="fixed inset-0 w-full h-full bg-black opacity-80"></div>
      <div className="flex items-center min-h-screen px-4 py-8">
        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-xl shadow-lg">
          <div className="mb-4 flex justify-between items-start">
            <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <p className="text-sm text-gray-700 mb-4">{content}</p>
          <p className="text-sm text-gray-700 mb-4">{contentTwo}</p>
          <p className="text-sm text-gray-700 mb-4">{contentThree}</p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
