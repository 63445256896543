import React from "react";
import Button from "../Shared/Button";
import Tick from "../../assets/images/LEO.png";
// import VideoSection from "../VideoSection";
// import TestVideo from "../../assets/videos/test.mp4";

const TickSection = () => {
  return (
    <section className="flex md:flex-row flex-col justify-center font-['DM_Sans'] md:gap-10 items-start  lg:max-w-[63%]  llaptop:max-w-[81%] tablet:max-w-[85%] w-full py-[20px] mx-auto">
      <div className="flex flex-col md:gap-8 gap-5 md:max-w-[600px] w-full">
        <div className="bg-[#FFFFFF1A] py-1 px-3 w-full md:max-w-[250px] max-w-[200px]">
          <p className="text-[#FFFFFF] uppercase md:text-[15px] text-[9px] font-normal tracking-[0.25em] grey_bg_text grey_bg_text">
            THE LEO CHALLENGE
          </p>
        </div>

        <div className="w-full max-w-[600px]">
          <h3 className="text-[30px] md:text-[58px] md:leading-[58px] font-[Aeonik] leading-[45px] font-medium text-[#FFFFFF]">
            Never forget the roots. The two-step evaluation is vital
          </h3>
        </div>

        <div className="w-full max-w-[420px]">
          <p className="text-[16px] md:text-[20px] font-normal text-[#FFFFFF] opacity-[80%] leading-7 grey_bg_text">
            Join the Leo 2-Phase Challenge and get paid in just 10 days! It's
            the easiest evaluation target with the most competitive price in the
            entire industry.
          </p>
        </div>

        <div>
          <Button
            text={"Start Trading"}
            className={"grey_bg_text"}
            link={true}
            href={"https://pay.fundedlions.com/"}
          />
        </div>
      </div>
      {/* 
      <div className="md:mt-0 mt-14">
        <VideoSection video1={TestVideo} video2={TestVideo} />
      </div> */}

      <div className="flex-1 flex justify-center md:mt-0 mt-14">
        <img
          src={Tick}
          alt=""
          className="w-full md:max-w-[500px] max-w-[400px]"
        />
      </div>
    </section>
  );
};

export default TickSection;
