import React from "react";
import Arrow from "../../assets/images/arrow.svg";

const PerPricingCard = () => {
  return (
    <div className="w-[300px] lg:w-[290px] xl:w-[371px] mx-auto main_price_card">
      <div className="flex flex-col justify-start  bg-black text-white rounded-[40px] shadow-md px-6 py-3">
        <div className=" flex flex-col gap-1 justify-center items-center text-center mb-1">
          <span className=" text-[10px] font-normal font-[Outfit]">
            THE BOSS
          </span>
          <div className=" flex gap-3 justify-center items-center text-center">
            <h3 className=" text-[26px] font-medium text-orange-500 font-[Outfit]">
              2 Percenter
            </h3>
            <img className=" cursor-pointer" src={Arrow} alt="" />
          </div>
          <span className=" text-[10px] font-normal font-[Outfit]">
            INSTANT FUNDING
          </span>
        </div>
        <div className="space-y-4">
          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className=" font-normal text-lg font-[Outfit]">
                Min & Max Gain
              </span>
              {/* <span className="flex-grow  mx-2"></span> */}
              <span className="text-base font-semibold font-[Outfit]">2%</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Simply achieve a minimum or maximum gain of 2% on your funded
              account and withdraw whenever you like.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="font-normal text-lg font-[Outfit]">
                Consistency Rule
              </span>
              <span className="text-base font-semibold font-[Outfit]">NO</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              No hidden rules or restrictions—trade however you prefer.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="font-normal text-lg font-[Outfit]">
                Max Loss
              </span>
              <span className="text-base font-semibold font-[Outfit]">10%</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              The highest maximum loss among all instant programs available.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="font-normal text-lg font-[Outfit]">
                Daily loss
              </span>
              <span className="text-base font-semibold font-[Outfit]">3%</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Maintain a daily loss limit of 3%
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="font-normal text-lg font-[Outfit]">
                Profit Split
              </span>
              <span className="text-base font-semibold font-[Outfit]">
                15% + 2% scale up
              </span>
            </div>
            <div className="text-[8px] flex items-center justify-between  hero_text font-[Outfit]">
              <span className="w-44">
                Own it like it's yours—receive 15% split on your payouts and
                withdraw it anytime enjoy scaling up!
              </span>
              <span className="text-[#DCDCDC]">On each payout</span>
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="font-normal text-lg font-[Outfit]">
                News Trading
              </span>
              <span className="text-base font-semibold font-[Outfit]">
                All Allowed
              </span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              We're here to evolve: trade on news and profit without denials or
              rejections anymore.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="font-normal text-lg font-[Outfit]">
                Weekend Holding
              </span>
              <span className="text-base font-semibold font-[Outfit]">
                Allowed
              </span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              At its peak, enjoy ultimate freedom to hold your trades over the
              weekend without any restrictions.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="font-normal text-lg font-[Outfit]">
                Withdrawal
              </span>
              <span className="text-base font-semibold font-[Outfit]">
                Instant
              </span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Take control of your profits and withdraw them anytime, just like
              your own funds.
            </div>
          </div>

          <div className=" pb-5">
            <div className="flex justify-between">
              <span className="font-normal text-lg font-[Outfit]">
                Profit Scale Up
              </span>
              <span className="text-base font-semibold font-[Outfit]">2%</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Increase your profit share by 2% with every payout you make.
              <br />
              <br />
              - 1st payout you’ll receive 15%
              <br />
              - 2nd payout you’ll receive 17%
              <br />
              - 3rd payout you’ll receive 19%
              <br />- 9th payout you’ll receive 30% (Max Payout)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerPricingCard;
