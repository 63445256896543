import React, { useState } from "react";
import LeoPricingCard from "./LeoPricingCard";
import PerPricingCard from "./PerPricingCard";
import PopPricingSection from "./PopPricingSection";
import Button from "../Shared/Button";
import Simba from "./Simba";

const PricingSection = () => {
  const tabs = ["10K", "25K", "50K", "100K", "200K"];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  // Pricing details for each tab
  const pricingDetails = {
    leo: {
      "10K": { beforeDiscount: 100, afterDiscount: 50 },
      "25K": { beforeDiscount: 185, afterDiscount: 130 },
      "50K": { beforeDiscount: 310, afterDiscount: 210 },
      "100K": { beforeDiscount: 495, afterDiscount: 347 },
      "200K": { beforeDiscount: 0, afterDiscount: 0 },
    },
    pop: {
      "10K": { price: 110 },
      "25K": { price: 275 },
      "50K": { price: 550 },
      "100K": { price: 1100 },
      "200K": { price: 2200 },
    },
    per: {
      "10K": { price: 100 },
      "25K": { price: 250 },
      "50K": { price: 500 },
      "100K": { price: 1000 },
      "200K": { price: 2000 },
    },
    simba: {
      "10K": { beforeDiscount: 105, afterDiscount: 94.5 },
      "25K": { beforeDiscount: 230, afterDiscount: 207 },
      "50K": { beforeDiscount: 350, afterDiscount: 315 },
      "100K": { beforeDiscount: 570, afterDiscount: 513 },
      "200K": { beforeDiscount: 0, afterDiscount: 0 },
    },
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section
      id="pricing"
      className="flex flex-col gap-5 justify-center items-center w-full md:w-4/5 py-4 mx-auto md:my-32"
    >
      <h1 className="font-medium text-2xl font-[Aeonik] md:text-4xl text-center text-white">
        Choose Your Account Size
      </h1>
      <div className="flex justify-center items-center price-tab md:w-[60%] lg:w-fit">
        {tabs.map((tab, i) => (
          <span
            key={tab}
            className={`px-4 py-2 font-[Outfit] md:px-12 text-center md:py-3 text-sm md:text-lg lg:text-xl font-bold cursor-pointer ${
              activeTab === tab ? "hero_text" : "text-white"
            } ${i !== tabs.length - 1 ? "price-tab-text" : ""}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </span>
        ))}
      </div>
      <div className="flex flex-col md:flex-row gap-5 md:gap-14 lg:gap-7 tablet:flex-wrap xl:gap-14 items-center md:items-start">
        <div className="w-full md:w-auto md:mt-20">
          <LeoPricingCard />
          {activeTab === "200K" ? (
            <div className="flex justify-center items-center mt-5">
              <h3 className="text-2xl md:text-3xl font-medium font-[Aeonik] text-white">
                Not Available
              </h3>
            </div>
          ) : (
            <div className="flex gap-3 justify-center items-center mt-5">
              <h3 className="text-lg md:text-2xl font-medium font-[Aeonik] text-white line-through opacity-50">
                ${pricingDetails.leo[activeTab].beforeDiscount}
              </h3>
              <h3 className="text-2xl md:text-4xl font-medium font-[Aeonik] text-white">
                ${pricingDetails.leo[activeTab].afterDiscount}
              </h3>
              <Button
                text={"Purchase"}
                link={"https://pay.fundedlions.com/"}
                href={"https://pay.fundedlions.com/"}
                className={"font-[Aeonik]"}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center items-center md:mt-12">
          <h3 className="text-[26px] font-bold text-[#FFFFFF] font-[Outfit]">
            Most Popular
          </h3>
          <PopPricingSection />

          <div className="flex gap-3 justify-center items-center mt-5">
            <h3 className="text-2xl md:text-4xl font-medium font-[Aeonik] text-white">
              ${pricingDetails.pop[activeTab].price}
            </h3>
            <Button
              text={"Purchase"}
              link={"https://pay.fundedlions.com/"}
              href={"https://pay.fundedlions.com/"}
              className={"font-[Aeonik]"}
            />
          </div>
        </div>
        <div className="w-full md:w-auto md:mt-20">
          <PerPricingCard />

          <div className="flex gap-3 justify-center items-center mt-5">
            <h3 className="text-2xl md:text-4xl font-medium font-[Aeonik] text-white">
              ${pricingDetails.per[activeTab].price}
            </h3>
            <Button
              text={"Purchase"}
              link={"https://pay.fundedlions.com/"}
              href={"https://pay.fundedlions.com/"}
              className={"font-[Aeonik]"}
            />
          </div>
        </div>
      </div>
      <div className="w-full md:w-auto md:mt-20">
        <Simba />

        {activeTab === "200K" ? (
          <div className="flex justify-center items-center mt-5">
            <h3 className="text-2xl md:text-3xl font-medium font-[Aeonik] text-white">
              Not Available
            </h3>
          </div>
        ) : (
          <div className="flex gap-3 justify-center items-center mt-5">
            <h3 className="text-lg md:text-2xl font-medium font-[Aeonik] text-white line-through opacity-50">
              ${pricingDetails.simba[activeTab].beforeDiscount}
            </h3>
            <h3 className="text-2xl md:text-4xl font-medium font-[Aeonik] text-white">
              ${pricingDetails.simba[activeTab].afterDiscount}
            </h3>
            <Button
              text={"Purchase"}
              link={"https://pay.fundedlions.com/"}
              href={"https://pay.fundedlions.com/"}
              className={"font-[Aeonik]"}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default PricingSection;
