import React from "react";

const Button = ({ text, className, link, href, onClick }) => {
  return link ? (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <button
        onClick={onClick}
        className={`rounded-[48px] px-8 xl:py-3 lg:py-[10px] py-2 text-base font-medium text-[#FFFFFF] btn_main ${className}`}
      >
        {text}
      </button>
    </a>
  ) : (
    <button
      onClick={onClick}
      className={`rounded-[48px] px-8 xl:py-3 lg:py-[10px] py-2 text-base font-medium text-[#FFFFFF] btn_main ${className}`}
    >
      {text}
    </button>
  );
};

export default Button;
