import React from "react";
import Button from "../Shared/Button";
import VideoSection from "../VideoSection";

const EarthSection = () => {
  return (
    <section className="flex md:flex-row flex-col items-center justify-center font-['DM_Sans'] md:gap-28 lg:max-w-[63%] llaptop:max-w-[85%] w-full py-[20px] mx-auto">
      <div className="flex flex-col md:gap-8 gap-5 md:w-[700px] mr-auto w-full">
        <div className="bg-[#FFFFFF1A] py-1 px-3 w-full md:max-w-[500px] max-w-[450px]">
          <p className="text-[#FFFFFF] uppercase md:text-[15px] text-[9px] font-normal tracking-[0.25em] grey_bg_text">
            With The Boss Instant Funding Program
          </p>
        </div>

        <div className="w-full max-w-[482px]">
          <h3 className="text-[30px] md:text-[58px] md:leading-[58px] font-[Aeonik] leading-[45px] font-medium text-[#FFFFFF]">
            1 percent a Day Keeps The Rules Away
          </h3>
        </div>

        <div className="w-full max-w-[420px]">
          <p className="text-[16px] md:text-[20px] font-normal text-[#FFFFFF] opacity-[80%] leading-7 grey_bg_text">
            Achieve a simple 1% gain on your instant funded account with zero
            restrictions. Enjoy perks like instant payouts
          </p>
        </div>

        <div>
          <Button
            text={"Start Trading"}
            className={"grey_bg_text"}
            link={true}
            href={"https://pay.fundedlions.com/"}
          />
        </div>
      </div>

      <div className="md:mt-0 mt-14">
        <VideoSection
          video1={
            "https://res.cloudinary.com/dulp4smej/video/upload/v1720997798/Project%20Videos/dqp8gpsiqk8omfq2aqog.mp4"
          }
          video2={
            "https://res.cloudinary.com/dulp4smej/video/upload/v1720997798/Project%20Videos/dqp8gpsiqk8omfq2aqog.mp4"
          }
        />
      </div>
    </section>
  );
};

export default EarthSection;
