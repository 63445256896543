import React from "react";
import Arrow from "../../assets/images/arrow.svg";

const LeoPricingCard = () => {
  return (
    <div className="w-[300px] lg:w-[290px] xl:w-[371px] mx-auto main_price_card">
      <div className="flex flex-col justify-start  bg-black text-white rounded-[40px] shadow-md px-6 py-8">
        <div className=" flex gap-3 justify-center items-center text-center text-orange-500 text-xl font-semibold mb-4">
          <h3 className=" text-[26px] font-medium font-[Outfit]">
            LEO Challenge
          </h3>

          <img className=" cursor-pointer" src={Arrow} alt="" />
        </div>
        <div className="space-y-4">
          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className=" font-normal text-lg font-[Outfit]">
                Profit Target P1
              </span>
              {/* <span className="flex-grow  mx-2"></span> */}
              <span className="text-base font-semibold font-[Outfit]">8%</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Hit your 8% target in Phase 1 and get set up for Phase 2
              challenge.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="text-lg font-[Outfit]">Profit Target P2</span>
              <span className="text-base font-semibold font-[Outfit]">5%</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              You're so close! Achieve a 5% gain on your account and lock in
              your funded account.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="text-lg font-[Outfit]">Max Loss</span>
              <span className="text-base font-semibold font-[Outfit]">8%</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Always maintain an 8% loss limit to stay on track.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="text-lg font-[Outfit]">Daily loss</span>
              <span className="text-base font-semibold font-[Outfit]">4%</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Stay sharp and manage your risk like a pro by keeping a daily loss
              limit of 4%.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="text-lg font-[Outfit]">Min Trading Days</span>
              <span className="text-base font-semibold font-[Outfit]">3</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              You need to trade for at least 3 days to meet the minimum trading
              requirement.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="text-lg font-[Outfit]">News Trading</span>
              <span className="text-base font-semibold font-[Outfit]">
                All Allowed
              </span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              We allow news trading even on funded accounts, with no restriction
              on your trades.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="text-lg font-[Outfit]">Weekend Holding</span>
              <span className="text-base font-semibold font-[Outfit]">
                Allowed
              </span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Yes, you heard right! No need to worry about trades closing on
              weekends—hold them without any concerns.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className="text-lg font-[Outfit]">Leverage</span>
              <span className="text-base font-semibold font-[Outfit]">
                1:50
              </span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              We enable all to offer our clients a leverage ratio of 1:50.
            </div>
          </div>

          <div className="">
            <div className="flex justify-between">
              <span className="text-lg font-[Outfit]">First Withdrawal</span>
              <span className="text-base font-semibold font-[Outfit]">
                10 Days
              </span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Request a withdrawal after completing 10 days of trading.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeoPricingCard;
