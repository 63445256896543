import React, { useEffect, useRef } from "react";

const data = [
  { title: "Total Payouts", value: "630$K" },
  { title: "Trades Joined", value: "7500+" },
  { title: "Countries From", value: "120+" },
  { title: "Fastest Payouts", value: "2 mins" },
  { title: "Total Payouts", value: "630$K" },
  { title: "Trades Joined", value: "7500+" },
  { title: "Countries From", value: "120+" },
  { title: "Fastest Payouts", value: "2 mins" },
  { title: "Total Payouts", value: "630$K" },
  { title: "Trades Joined", value: "7500+" },
  { title: "Countries From", value: "120+" },
  { title: "Fastest Payouts", value: "2 mins" },
  { title: "Total Payouts", value: "630$K" },
  { title: "Trades Joined", value: "7500+" },
  { title: "Countries From", value: "120+" },
  { title: "Fastest Payouts", value: "2 mins" },
  { title: "Total Payouts", value: "630$K" },
  { title: "Trades Joined", value: "7500+" },
  { title: "Countries From", value: "120+" },
  { title: "Fastest Payouts", value: "2 mins" },
  { title: "Total Payouts", value: "630$K" },
  { title: "Trades Joined", value: "7500+" },
  { title: "Countries From", value: "120+" },
  { title: "Fastest Payouts", value: "2 mins" },
];

const RunningSlider = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    let startPosition = 0;

    const scrollSlider = () => {
      startPosition -= 1.5;
      if (startPosition <= -slider.scrollWidth / 4) {
        startPosition = 0;
      }
      slider.style.transform = `translateX(${startPosition}px)`;
    };

    const interval = setInterval(scrollSlider, 10);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="overflow-hidden whitespace-nowrap text-white py-4 w-full md:my-32 my-10">
      <div className="inline-flex" ref={sliderRef}>
        <div className="flex items-center">
          {data.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center  justify-center gap-1 text-center px-8 flex-shrink-0"
            >
              <p className="text-[14px] text-[#FFFFFFB2] font-medium font-[Inter]">
                {item.title}
              </p>
              <p className="text-[32px] font-medium font-[Poppins] ">
                {item.value}
              </p>
            </div>
          ))}
          {data.map((item, index) => (
            <div
              key={index}
              className=" flex flex-col items-center gap-2  text-center px-8 flex-shrink-0"
            >
              <p className="text-[14px] text-[#FFFFFFB2] font-medium font-[Inter]">
                {item.title}
              </p>
              <p className="text-[32px] font-medium font-[Poppins] ">
                {item.value}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RunningSlider;
