import React, { useState } from "react";
import Logo from "../assets/images/MainLogo.svg";
import Button from "./Shared/Button";

const navData = [
  {
    name: "Home",
    link: "",
  },
  {
    name: "Challenge",
    link: "#pricing",
  },
  {
    name: "Affilates",
    link: "https://pay.fundedlions.com/affiliates/",
  },
  {
    name: "Contact",
    link: "#join_us",
  },
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="text-white font-[Aeonik] flex items-center justify-between lg:max-w-[68%] llaptop:max-w-[90%] w-full md:px-8 px-3 py-4 md:py-10 mx-auto relative">
      <div className="max-w-[229px]">
        <img
          src={Logo}
          alt="Funded Lions Logo"
          className="h-10 lg:h-10 xl:h-14 xl:w-full lg:w-48  mr-4"
        />
      </div>
      <nav
        className={`flex space-y-4 md:space-y-0 lg:space-x-5 laptop:space-x-2 tablet:hidden absolute md:relative bg-gray-900 md:bg-transparent w-full md:w-auto left-0 top-12 md:top-auto md:left-auto p-4 md:p-0 z-10`}
      >
        {
          navData.map((item) => {
            const isInternalLink = item.link.startsWith('#');
            return (
              <a
                href={item.link}
                className="hover:text-orange-500 lg:text-[14px] xl:text-[17px] font-medium"
                target={isInternalLink ? "_self" : "_blank"}
                rel={isInternalLink ? "" : "noopener noreferrer"}
              >
                {item.name}
              </a>
            );
          })
        }


        <Button
          text={"Dashboard"}
          className={"md:hidden block font-[Aeonik]"}
        />
      </nav>
      <div className="flex space-x-4 md:space-x-8 items-center">
        <a
          href="https://intercom.help/fundedlions/en"
          target="_blank"
          className="lg:block hidden hover:text-orange-500 lg:text-[14px] xl:text-[17px] font-medium"
        >
          FAQ
        </a>
        <Button
          text={"Dashboard"}
          link={true}
          href={"https://app.fundedlions.com/"}
          className="md:block hidden lg:text-[14px] xl:text-[17px] font-[Aeonik]"
        />

        <div onClick={toggleMenu} className="md:hidden tablet:block">
          <svg
            className="w-6 h-6 mr-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </div>
      </div>

      {isOpen && (
        <nav
          className={`flex flex-col lg:hidden items-center gap-6 justify-center absolute w-full h-screen bg-black top-0 left-0 z-10`}
        >
          {
            navData.map((item) => {
              const isInternalLink = item.link.startsWith('#');
              return (
                <a href={item.link} className="hover:text-orange-500 text-[14px] text-white"
                  target={isInternalLink ? "_self" : "_blank"}
                  rel={isInternalLink ? "" : "noopener noreferrer"}
                  onClick={() => {
                    setIsOpen(false)
                  }}>
                  {item.name}
                </a>
              )
            })
          }

          <div className="absolute top-10 right-5" onClick={toggleMenu}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Header;
