import React from "react";
import Button from "../Shared/Button";

const JoinUs = () => {
  return (
    <>
      <section
        id="join_us"
        className="mx-auto md:w-[70%] w-[88%] join-us_section my-[10rem] -mb-10"
      >
        <div className="flex flex-col justify-center items-center gap-8 md:px-4 px-3 md:py-12 py-10  join_us rounded-[40px]">
          <div className="bg-[#FFFFFF1A] py-1 px-3">
            <p className="text-[#FFFFFF] uppercase text-[15px] font-normal tracking-[0.25em]">
              join us now
            </p>
          </div>

          <div className="w-full max-w-[800px]">
            <h1 className="text-[28px] sm:text-[48px] md:text-[56px] text-[#FFFFFF] font-normal text-center">
              Have More Questions? We're here to help.
            </h1>
          </div>

          <div className="w-full max-w-[850px] text-center">
            <p className="md:text-base text-sm font-medium text-[#FFFFFF] opacity-[70%]">
              We would love to hear from you,Get in touch with us
            </p>
          </div>

          <Button
            text={"Contact Us"}
            link={true}
            href="mailto:support@fundedlions.com"
            className="additional-classes-if-needed"
          />
        </div>
      </section>
    </>
  );
};

export default JoinUs;
