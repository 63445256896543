import React from "react";
import PhoneImage from "../../assets/images/laptop.png";
import bag from "../../assets/images/bag.png";
import coin from "../../assets/images/coin.png";
import hammer from "../../assets/images/hammer.png";

const HeroSection = () => {
  return (
    <>
      <section className="flex flex-col md:flex-row justify-center my-20 font-[Outfit] md:gap-5 items-center lg:max-w-[85%] llaptop:max-w-[85%] w-full py-4 mx-auto">
        <div className="flex flex-col md:gap-8 gap-5 md:my-20 my-10 md:max-w-[580px] w-full">
          <div className="w-full max-w-[500px]">
            <p className="uppercase text-sm font-medium font-[Aeonik] tracking-wider hero_text">
              Get ready for a thrilling new ERA!
            </p>
          </div>

          <div className="w-full max-w-[600px]">
            <h3 className="text-3xl md:text-5xl font-bold text-white">
              UNLOCK! IMPOSSIBLE
            </h3>
          </div>

          <div>
            <p className="text-lg md:text-4xl font-bold text-white">
              <span className="flex items-center">
                Instant Funding
                <img
                  src={bag}
                  className="ml-2 w-6 h-6 md:w-10 md:h-10"
                  alt="bag"
                />
              </span>

              <span className="flex items-center mt-2">
                Instant Payouts
                <img
                  src={coin}
                  className="ml-2 w-6 h-6 md:w-10 md:h-10"
                  alt="coin"
                />
              </span>

              <span className="flex items-center mt-2 hero_text">
                No Rules, No Restrictions
                <img
                  src={hammer}
                  className="ml-2 w-6 h-6 md:w-10 md:h-10"
                  alt="hammer"
                />
              </span>
            </p>
          </div>

          <div className="w-full max-w-[420px] font-[Aeonik]">
            <p className="text-base md:text-lg font-medium text-white leading-7">
              Withdrawal in Anytime, Anywhere!
            </p>
          </div>
        </div>

        <div className="max-w-[620px]">
          <img
            src={PhoneImage}
            alt="Phone"
            className="w-full h-[300px] object-contain"
          />
        </div>
      </section>
    </>
  );
};

export default HeroSection;
