import React from "react";
import SingleFeature from "./SingleFeature";
import SecondFeature from "./SecondFeature";

const FeatureSection = () => {
  return (
    <section className="flex flex-col gap-10 tablet:flex-wrap  justify-center items-center md:max-w-[80%] max-w-[90%] w-full py-[14px] mx-auto my-20">
      <h1 className="font-medium text-2xl font-[Aeonik] md:text-4xl text-center text-white">
        Comparison
      </h1>
      <div className=" gap-10 md:grid lg:grid-cols-2  tablet:grid-cols-1 flex flex-col tablet:flex-wrap justify-center items-center md:min-h-[500px] min-h-screen">
        <SingleFeature className="mb-3 md:mb-0" />
        {/* Adjust spacing for mobile */}
        <SecondFeature className="mb-1 md:mb-0" />
        {/* Adjust spacing for mobile */}
      </div>
    </section>
  );
};

export default FeatureSection;
