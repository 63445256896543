import { useEffect } from "react";

export default function IntercomComponent() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.intercom.io/widget/y9umgczb";
    script.async = true;

    script.onload = () => {
      window.Intercom("boot", {
        app_id: "y9umgczb",
      });
    };

    script.onerror = () => {
      console.error("Failed to load Intercom script");
    };

    document.body.appendChild(script);

    return () => {
      if (window.Intercom) {
        window.Intercom("shutdown");
      }
      document.body.removeChild(script);
    };
  }, []);

  return null;
}
