import React, { useEffect, useRef, useState } from "react";
import "../../style/InfiniteTicker.css";
import Tick from "../../assets/images/check.png";
import OrangeTick from "../../assets/images/orcheck.png";

const Test = () => {
  const containerRef = useRef(null);
  // const [startMarquee, setStartMarquee] = useState(false);
  // let timer;

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         timer = setTimeout(() => {
  //           setStartMarquee(true);
  //         }, 2000);
  //       } else {
  //         clearTimeout(timer);
  //         setStartMarquee(false);
  //       }
  //     },
  //     { threshold: 0.1 }
  //   );

  //   if (containerRef.current) {
  //     observer.observe(containerRef.current);
  //   }

  //   return () => {
  //     clearTimeout(timer);
  //     if (containerRef.current) {
  //       observer.unobserve(containerRef.current);
  //     }
  //   };
  // }, []);

  const staticContent = (
    <div className="flex gap-3 justify-center items-center font-[Outfit]">
      <span className="flex justify-center ml-3 items-center gap-2 md:text-[52px] font-light text-xl">
        Direct Funded{" "}
        <img
          src={Tick}
          alt=""
          className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
        />
      </span>
      <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
        Instant Payout
        <img
          src={Tick}
          alt=""
          className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
        />
      </span>
      <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
        No Rules & Restrictions
        <img
          src={Tick}
          alt=""
          className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
        />
      </span>
      <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
        The Boss{" "}
      </span>
      <span className="flex justify-center ml-3 items-center gap-2 md:text-[52px] font-light text-xl">
        Direct Funded{" "}
        <img
          src={Tick}
          alt=""
          className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
        />
      </span>
      <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
        Instant Payout
        <img
          src={Tick}
          alt=""
          className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
        />
      </span>
      <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
        No Rules & Restrictions
        <img
          src={Tick}
          alt=""
          className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
        />
      </span>
      <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
        The Boss{" "}
      </span>
      <span className="flex justify-center ml-3 items-center gap-2 md:text-[52px] font-light text-xl">
        Direct Funded{" "}
        <img
          src={Tick}
          alt=""
          className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
        />
      </span>
      <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
        Instant Payout
        <img
          src={Tick}
          alt=""
          className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
        />
      </span>
      <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
        No Rules & Restrictions
        <img
          src={Tick}
          alt=""
          className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
        />
      </span>
      <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
        The Boss{" "}
      </span>
    </div>
  );

  const marqueeContent1 = (
    <marquee
      className="flex h-fit text-white first-div text-3xl rotate-[5deg] w-full md:pt-5 md:pb-5 pt-2 pb-2"
      behavior="scroll"
      direction="left"
      scrollamount="15"
    >
      {staticContent}
    </marquee>
  );

  const marqueeContent2 = (
    <marquee
      className="flex h-fit text-[#F53E07] second-div text-3xl -rotate-[3deg] w-full md:pt-5 md:pb-5 pt-2 pb-2"
      behavior="scroll"
      direction="right"
      scrollamount="15"
    >
      <div className="flex gap-3 justify-center items-center font-[Outfit]">
        <span className="flex justify-center ml-3 items-center gap-2 md:text-[52px] font-light text-xl">
          Direct Funded{" "}
          <img
            src={OrangeTick}
            alt=""
            className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
          />
        </span>
        <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
          Instant Payout
          <img
            src={OrangeTick}
            alt=""
            className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
          />
        </span>
        <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
          No Rules & Restrictions
          <img
            src={OrangeTick}
            alt=""
            className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
          />
        </span>
        <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
          The Boss{" "}
        </span>
        <span className="flex justify-center ml-3 items-center gap-2 md:text-[52px] font-light text-xl">
          Direct Funded{" "}
          <img
            src={OrangeTick}
            alt=""
            className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
          />
        </span>
        <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
          Instant Payout
          <img
            src={OrangeTick}
            alt=""
            className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
          />
        </span>
        <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
          No Rules & Restrictions
          <img
            src={OrangeTick}
            alt=""
            className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
          />
        </span>
        <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
          The Boss{" "}
        </span>
        <span className="flex justify-center ml-3 items-center gap-2 md:text-[52px] font-light text-xl">
          Direct Funded{" "}
          <img
            src={OrangeTick}
            alt=""
            className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
          />
        </span>
        <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
          Instant Payout
          <img
            src={OrangeTick}
            alt=""
            className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
          />
        </span>
        <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
          No Rules & Restrictions
          <img
            src={OrangeTick}
            alt=""
            className="ml-2 mr-2 md:mr-10 md:w-8 w-4 md:h-8 h-4"
          />
        </span>
        <span className="flex justify-center items-center gap-2 md:text-[52px] font-light text-xl">
          The Boss{" "}
        </span>
      </div>
    </marquee>
  );

  return (
    <div
      ref={containerRef}
      className="h-[70vh] flex justify-center w-full whitespace-nowrap overflow-x-hidden"
    >
      <div className="flex flex-col w-full items-center justify-center">
        {marqueeContent1}
        {marqueeContent2}
        {/* {startMarquee ? (
          marqueeContent1
        ) : (
          <div className="flex h-fit text-white first-div text-3xl rotate-[5deg] w-full md:pt-5 md:pb-5 pt-2 pb-2">
            {staticContent}
          </div>
        )}
        {startMarquee ? (
          marqueeContent2
        ) : (
          <div className="flex h-fit text-[#F53E07] second-div text-3xl -rotate-[3deg] w-full md:pt-5 md:pb-5 pt-2 pb-2">
            {staticContent}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Test;
