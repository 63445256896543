import React, { useState } from "react";
import fbIcon from "../assets/images/Facebook.png";
import instaIcon from "../assets/images/Instagram.png";
import discordIcon from "../assets/images/discord.png";
import twitterIcon from "../assets/images/Twitter.png";
import Logo from "../assets/images/MainLogo.svg";
import Button from "./Shared/Button";
import Modal from "./Shared/Modal";
const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <footer className="text-white md:py-14 py-5 bg-[#0f0700] footer_text md:mt-0 mt-[4rem]">
      <div className="container flex flex-col gap-4 justify-center items-center mx-auto text-center">
        <div className="mb-4">
          <img
            src={Logo}
            alt="Fundedlions Logo"
            className="mx-auto h-12 sm:h-14 mb-4"
          />
        </div>
        <div className="flex flex-wrap justify-center md:space-x-6 space-x-3 mb-4 md:text-sm text-[13px]">
          <a href="/" className="hover:underline">
            Home
          </a>
          <a href="#pricing" className="hover:underline">
            Challenges
          </a>
          <a
            href="https://pay.fundedlions.com/affiliates/"
            className="hover:underline"
          >
            Affiliates
          </a>
          <a href="#join_us" className="hover:underline">
            Contact
          </a>
        </div>

        <div className="flex justify-center space-x-4 mb-4">
          <a href="https://www.facebook.com/fundedlions">
            <img
              src={fbIcon}
              alt="Facebook"
              className="h-5 w-5 sm:h-6 sm:w-6"
            />
          </a>
          <a href="https://twitter.com/fundedlions">
            <img
              src={twitterIcon}
              alt="Twitter"
              className="h-5 w-5 sm:h-6 sm:w-6"
            />
          </a>
          <a href="https://discord.com/invite/fundedlions">
            <img
              src={discordIcon}
              alt="Discord"
              className="h-5 w-5 sm:h-6 sm:w-6"
            />
          </a>
          <a href="https://www.instagram.com/fundedlions/ ">
            <img
              src={instaIcon}
              alt="Instagram"
              className="h-5 w-5 sm:h-6 sm:w-6"
            />
          </a>
        </div>
        <div className="text-sm font-medium">
          <p>&copy; Fundedlions. All rights reserved</p>
          <div className="flex flex-col items-center gap-5 justify-between">
            <p className="max-w-[60rem] tablet:max-w-[30rem] lmobile:max-w-[25rem] w-full">
              All content published and distributed by Funded Lions and its
              affiliates (collectively, the "Company") is to be treated as
              general information only. None of the information provided by the
              Company or contained herein is intended as investment advice, an
              offer or solicitation of an offer to buy or sell, or a
              recommendation, endorsement, or sponsorship of any security,
              company, or fund. Use of the information contained on the
              Company's websites is at your own risk, and the Company assumes no
              responsibility or liability for any use or misuse of such
              information. Nothing contained herein is a solicitation or an
              offer to buy or sell futures, options, or forex. Past performance
              is not necessarily indicative of future results.
            </p>
            <Button
              text={"Funded Demo Account Disclaimer"}
              className="additional-classes-if-needed"
              onClick={handleModalToggle}
            />
          </div>
        </div>
      </div>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleModalToggle}
          title="Funded Demo Account Disclaimer"
          content="Hypothetical or simulated performance results have certain limitations. Unlike an actual performance record, simulated results do not represent actual trading. Also, because the trades have not actually been executed, the results may have under-or-over compensated for the impact, if any, of certain market factors, such as lack of liquidity. Simulated trading programs, in general, are also subject to the fact that they are designed with the benefit of hindsight. No representation is being made that any account will or is likely to achieve profit or losses similar to those shown."
          contentTwo="The FundedLions funded accounts are not live trading accounts, they are fully simulated accounts utilizing real market quotes from liquidity providers. The Traders featured are representative only and includes selected traders who allow their names to be publicly disclosed. Trader performance quoted represents past performance in a simulated environment and does not guarantee current or future performance."
          contentThree="Further, financial instrument trading involves substantial risk of loss and is not suitable for every investor. Trading activities referenced by or undertaken utilizing FundedLions may not be suitable for all persons."
        />
      )}
    </footer>
  );
};

export default Footer;
