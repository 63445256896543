import React from "react";

const Card = ({ title, highlightedText, description, bgColor }) => {
  return (
    <>
      <div
        className={`card-main bg-[${bgColor}] lg:w-[538px] laptop:max-w-[576px] laptop:w-full tablet:w-full`}
      >
        <div className=" flex flex-col md:gap-8 gap-3 py-8 md:px-10 px-5">
          <div className="text-[38px] font-bold">
            <span className=" text-[#FFFFFF] md:text-[38px] text-[30px] font-bold font-[Outfit]">
              {title}
            </span>
            <br />
            <span className="card_text md:text-[38px] text-[30px] font-bold font-[Outfit]">
              {highlightedText}
            </span>
          </div>

          <div className=" text-[16px] font-medium font-[Aeonik] text-[#C5C5C5] max-w-[380px]">
            <p className=" capitalize">{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
