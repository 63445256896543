import React from "react";
import Card from "./Card";

const data = [
  {
    title: "300+ Trading",
    description:
      "Explore Over 300 Trading Instruments For Endless Opportunities In The Financial Markets.",
    highlightedText: "Instruments",
    bgColor: "#05040373",
  },
  {
    title: "Instant Funded",
    description:
      "Start Trading Instantly With Our Funded Accounts, No Waiting Necessary!",
    highlightedText: "Accounts",
    bgColor: "#171412",
  },
  {
    title: "Endless",
    description:
      "Discover Endless Opportunities To Grow And Succeed In Trading With Funded Lions.",
    highlightedText: "Opportunities",
    bgColor: "#171412",
  },
  {
    title: "Supportive",
    description:
      "Join Our Supportive Community Dedicated To Helping You Thrive In Your Trading Journey.",
    highlightedText: "Community",
    bgColor: "#171412",
  },
];

const WhyBestSection = () => {
  return (
    <>
      <section className="flex flex-col gap-8 justify-center items-center max-w-[80%] w-full py-[14px] m-auto md:my-10 my-5">
        <div className="bg-[#FFFFFF1A] flex justify-center items-center text-center py-1 px-3 md:max-w-[500px] max-w-[450px]">
          <p className="text-[#FFFFFF] uppercase md:text-[15px] text-[9px] font-normal tracking-[0.25em] font-[Aeonik]">
            Why we are best for you
          </p>
        </div>
        <h1 className=" md:text-[56px] text-[30px] md:leading-[58px] leading-[45px]  font-medium text-center text-[#FFFFFF] font-[Aeonik]">
          Built By Trader For Trader
        </h1>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 m-auto">
          {data.map((item, i) => {
            return <Card key={i} {...item} />;
          })}
        </div>
      </section>
    </>
  );
};

export default WhyBestSection;
