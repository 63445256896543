import React from "react";
import sliderGroup from "../../assets/images/Testimonials.png";

const LoveUsSection = () => {
  return (
    <div className=" flex text-white md:py-12 py-3 mb-16 md:mb-10 mx-auto">
      <div className="mx-auto px-4">
        <div className="bg-[#FFFFFF1A] mx-auto py-1 px-3 w-full md:max-w-[178px] max-w-[140px]">
          <p className="text-[#FFFFFF] uppercase md:text-[15px] text-[12px] font-normal tracking-[0.25em]">
            Testimonials
          </p>
        </div>
        <h2 className="text-center md:text-[56px] text-[30px] font-bold">
          Traders L🧡ve Us
        </h2>
        <p className="text-center text-[10px] text-[#FFFFFFB2] uppercase mb-8">
          Some reviews from our traders around the World.
        </p>

        <div>
          <img src={sliderGroup} alt="slider" />
        </div>
      </div>
    </div>
  );
};

export default LoveUsSection;
