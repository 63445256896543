import React from "react";
import Button from "../Shared/Button";
import VideoSection from "../VideoSection";

const DollarSection = () => {
  return (
    <section className="flex flex-col md:flex-row justify-center items-center font-['DM_Sans'] md:max-w-[81%] llaptop:max-w-[97%] tablet:max-w-[81%] w-full my-[10rem] py-[20px] mx-auto gap-10">
      <div className="flex-1 w-full md:px-0 px-6">
        <VideoSection
          video1={
            "https://res.cloudinary.com/dulp4smej/video/upload/v1720997805/Project%20Videos/xu5aljxbnvv38on6eyh0.mp4"
          }
          video2={
            "https://res.cloudinary.com/dulp4smej/video/upload/v1720997805/Project%20Videos/xu5aljxbnvv38on6eyh0.mp4"
          }
        />
      </div>

      <div className="flex-1 flex flex-col md:gap-8 gap-5 md:w-[800px] w-full">
        <div className="bg-[#FFFFFF1A] py-1 px-3 w-full md:max-w-[500px] max-w-[450px]">
          <p className="text-[#FFFFFF] uppercase md:text-[15px] text-[9px] font-normal tracking-[0.25em] grey_bg_text">
            With The Boss Instant Funding Program
          </p>
        </div>

        <div className="w-full max-w-[482px]">
          <h3 className="text-[#FFFFFF] text-[30px] md:text-[58px] md:leading-[58px] leading-[45px] font-[Aeonik] font-medium">
            2 Percent a Day double your Gains & Lead The Way
          </h3>
        </div>

        <div className="w-full max-w-[420px]">
          <p className="text-[#FFFFFF] text-[16px] md:text-[20px] font-normal opacity-[80%] leading-7 grey_bg_text">
            Just Like 1% Plan. Get Paid Instantly Everytime you hit 2% Gain on
            Account with any trading style, Even News Trading or Weekend
            Holding, and a pro scaling plan!
          </p>
        </div>

        <div>
          <Button
            text={"Start Trading"}
            className={"grey_bg_text"}
            link={true}
            href={"https://pay.fundedlions.com/"}
          />
        </div>
      </div>
    </section>
  );
};

export default DollarSection;
