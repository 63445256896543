import React from "react";
import Arrow from "../../assets/images/arrow.svg";

const PopPricingSection = () => {
  return (
    <div className="md:w-[350px] lg:w-[325px] xl:w-[350px] w-[300px] mx-auto main_price_pop_card mt-5">
      <div className="flex flex-col justify-start bg-black text-white md:p-6 p-4 rounded-[40px] shadow-md">
        <div className=" flex flex-col gap-1 justify-center items-center text-center mb-1">
          <span className=" text-[10px] font-normal">THE BOSS</span>
          <div className=" flex gap-3 justify-center items-center text-center">
            <h3 className="text-[26px] font-medium text-orange-500 font-[Outfit]">
              1 Percenter
            </h3>
            <img className=" cursor-pointer" src={Arrow} alt="" />
          </div>
          <span className=" text-[10px] font-normal">INSTANT FUNDING</span>
        </div>
        <div className="space-y-4">
          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className=" font-normal text-lg font-[Outfit]">
                Min & Max Gain
              </span>

              <span className="text-base font-semibold font-[Outfit]">1%</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Simply achieve a minimum or maximum gain of 1% on your funded
              account and withdraw whenever you like.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className=" font-normal text-lg font-[Outfit]">
                Consistency Rule
              </span>
              <span className="text-base font-semibold font-[Outfit]">NO</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              No hidden rules or restrictions—trade however you prefer.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className=" font-normal text-lg font-[Outfit]">
                Max Loss
              </span>
              <span className="text-base font-semibold font-[Outfit]">6%</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              The highest maximum loss among all instant programs available.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className=" font-normal text-lg font-[Outfit]">
                Daily loss
              </span>
              <span className="text-base font-semibold font-[Outfit]">4%</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Maintain a daily loss limit of 4%
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className=" font-normal text-lg font-[Outfit]">
                Profit Split
              </span>
              <span className="text-base font-semibold font-[Outfit]">25%</span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Own it like it's yours—receive a 25% split on your first payout
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className=" font-normal text-lg font-[Outfit]">
                News Trading
              </span>
              <span className="text-base font-semibold font-[Outfit]">
                All Allowed
              </span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              We're here to evolve: trade on news and profit without denials or
              rejections anymore.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className=" font-normal text-lg font-[Outfit]">
                Weekend Holding
              </span>
              <span className="text-base font-semibold font-[Outfit]">
                Allowed
              </span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              At its peak, enjoy ultimate freedom to hold your trades over the
              weekend without any restrictions.
            </div>
          </div>

          <div className=" border-b border-dashed border-[#FFFFFFB2] pb-3">
            <div className="flex justify-between">
              <span className=" font-normal text-lg font-[Outfit]">
                Withdrawal
              </span>
              <span className="text-base font-semibold font-[Outfit]">
                Instant
              </span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Take control of your profits and withdraw them anytime, just like
              your own funds.
            </div>
          </div>

          <div className="">
            <div className="flex justify-between">
              <span className=" font-normal text-lg font-[Outfit]">
                Profit Scale Up
              </span>
              <span className="text-base font-semibold font-[Outfit]">
                None
              </span>
            </div>
            <div className="text-[8px] w-44 hero_text font-[Outfit]">
              Enjoy a fixed 25% split on your payouts without any scale up!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopPricingSection;
