import React from "react";
import Fea1 from "../../assets/images/com_logo.svg";
import Fea2 from "../../assets/images/ic.svg";
import { IoClose } from "react-icons/io5";
import { IoCloseCircle } from "react-icons/io5";
import { IoIosCheckmarkCircle } from "react-icons/io";
import featureData from "../../mock/secondFeature.json";

const SecondFeature = () => {
  return (
    <div className=" h-full flex md:gap-9 gap-2">
      <div className=" flex flex-col gap-2 bg-[#1C110ABF] border border-[#fff] text-white rounded-lg shadow-md md:p-6 p-3 mx-auto w-full md:max-w-[220px] max-w-[140px]">
        <h3 className=" md:text-4xl text-3xl font-medium h-14 font-[Aeonik]">Features</h3>
        <div className=" flex flex-col h-full divide-y text-orange-500 mt-3">
          {featureData.Details.map((feature, i) => (
            <p
              key={i}
              className=" md:text-[13px] md:py-0 py-2 flex-1 flex items-center text-xs text-white font-[Outfit]"
            >
              {feature}
            </p>
          ))}
        </div>
      </div>
      <div className=" flex  md:gap-2 gap-1">
        <div className=" flex flex-col gap-3 bg-[#1C110ABF] border border-[#fff] text-white rounded-lg shadow-md md:py-6 md:px-4 p-3 mx-auto ">
          <img className=" object-contain md:h-12 h-10 md:w-32" src={Fea1} alt="Fea1" />
          <div className=" flex flex-col h-full divide-y text-[#FFFFFF] mt-3">
            {featureData.FundedLions.map((feature, i) => {
              if (typeof feature === "boolean") {
                return feature ? (
                  <div key={i} className="flex-1 md:py-0 py-2 flex  items-center justify-center">
                    <IoIosCheckmarkCircle className=" text-white md:text-[22px] text-[18px]" />
                  </div>
                ) : (
                  <div key={i} className="flex-1 md:py-0 py-2 flex  items-center justify-center">
                    <IoCloseCircle className=" text-white md:text-[22px] text-[18px]" />
                  </div>
                );
              }
              return (
                <div
                  key={i}
                  className="flex-1 md:py-0 py-2 flex items-center justify-center text-center text-xs font-normal"
                >
                  {feature}
                </div>
              );
            })}
          </div>
        </div>

        <div className=" flex flex-col gap-3 bg-[#1C110ABF] border border-[#fff] text-white rounded-lg shadow-md md:py-6 md:px-4 p-3 mx-auto ">
          <img className=" object-contain md:h-12 h-10 md:w-32" src={Fea2} alt="Fea1" />
          <div className=" flex flex-col h-full divide-y text-[#FFFFFF] mt-3">
            {featureData.ICMarkets.map((feature, i) => {
              if (typeof feature === "boolean") {
                return feature ? (
                  <div key={i} className="flex-1 md:py-0 py-2 flex  items-center justify-center">
                    <IoIosCheckmarkCircle className=" text-white md:text-[22px] text-[18px]" />
                  </div>
                ) : (
                  <div key={i} className="flex-1 md:py-0 py-2 flex  items-center justify-center">
                    <IoCloseCircle className=" text-white md:text-[22px] text-[18px]" />
                  </div>
                );
              }
              return (
                <div
                  key={i}
                  className="flex-1 md:py-0 py-2 flex items-center justify-center text-center text-xs font-normal"
                >
                  {feature}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondFeature;
