import React from "react";
import Header from "../components/Header";
import EarthSection from "../components/EarthSection/EarthSection";
import DollarSection from "../components/DollarSection/DollarSection";
import WhyBestSection from "../components/WhyBestSection/WhyBestSection";
import TickSection from "../components/TickSection/TickSection";
import HeroSection from "../components/HeroSection/HeroSection";
import RunningSlider from "../components/RunningSlider/RunningSlider";
import Footer from "../components/Footer";
import JoinUs from "../components/JoinUsSection/JoinUs";
import PricingSection from "../components/PricingSection/PricingSection";
// import InfiniteTicker from "../components/InfiniteTicker/InfiniteTicker";
import Test from "../components/InfiniteTicker/Test";
import FeatureSection from "../components/FeatureSection/FeatureSection";
import LoveUsSection from "../components/LoveUsSection/LoveUsSection";
// import AboutUs from "../components/AboutUs/AboutUs";
import bgFrame from "../assets/images/backgroundFrame.png";
import IntercomComponent from "../components/Intercom/InterCom";
const Home = () => {
  return (
    <>
      <IntercomComponent />
      <div
        className="bg-[#0f0700]"
        style={{
          backgroundImage: `url(${bgFrame})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Header />
        <HeroSection />
        <RunningSlider />
        <EarthSection />
        <DollarSection />
        <TickSection />
        <PricingSection />
        <FeatureSection />
        {/* <AboutUs/> */}
        <WhyBestSection />
        {/* <InfiniteTicker /> */}
        <Test />
        <LoveUsSection />
        <JoinUs />
        <Footer />
      </div>
    </>
  );
};

export default Home;
